﻿/*
 *  Document   : _general.scss
 *  Author     : RedStar Template
 *  Description: This scss file for all common style classes
 */

body.light {
  background-color: white;
  overflow-x: hidden;
}

body,
html {
  @include transition(all 0.5s);
  font-family: $sidebar-font-family;
  font-size: 14px;
}

button,
input,
select,
a {
  outline: none !important;
  font-size: 14px !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

textarea {
  font-size: 14px !important;
}

.row {
  margin-bottom: 0px !important;
}

ol,
ul,
dl {
  padding-left: 0px;
  list-style-type: none;
}

.no-animate {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

section {
  &.content {
    margin: 35px 27px 0 260px;
    min-height: calc(100vh - 76px);
    @include transition(0.5s);
    .content-block {
      padding: 0px 0px 0px 25px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .content-block {
    padding: 10px !important;
  }

}
.horizontal-layout {
  section {
    &.content {
      margin: 170px 15px 0 15px;
      float: left;
      width: calc(100% - 30px);
    }
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.msl-1 {
  margin-left: 0.25rem !important;
}
.msl-2 {
  margin-left: 0.5rem !important;
}
.msl-3 {
  margin-left: 1rem !important;
}
.msl-4 {
  margin-left: 1.5rem !important;
}
.msl-5 {
  margin-left: 3rem !important;
}

.msr-1 {
  margin-right: 0.25rem !important;
}
.msr-2 {
  margin-right: 0.5rem !important;
}
.msr-3 {
  margin-right: 1rem !important;
}
.msr-4 {
  margin-right: 1.5rem !important;
}
.msr-5 {
  margin-right: 3rem !important;
}

.psl-1 {
  padding-left: 0.25rem !important;
}
.psl-2 {
  padding-left: 0.5rem !important;
}
.psl-3 {
  padding-left: 1rem !important;
}
.psl-4 {
  padding-left: 1.5rem !important;
}
.psl-5 {
  padding-left: 3rem !important;
}

.psr-1 {
  padding-right: 0.25rem !important;
}
.psr-2 {
  padding-right: 0.5rem !important;
}
.psr-3 {
  padding-right: 1rem !important;
}
.psr-4 {
  padding-right: 1.5rem !important;
}
.psr-5 {
  padding-right: 3rem !important;
}
.cursor-pointer {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 15px;
  height: 15px;
}

table {
  .checkbox {
    [type="checkbox"] + label {
      margin: 0;
      height: 20px;
      padding-left: 20px;
      vertical-align: middle;
    }
  }
}

.loading-img-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -60px 0 20px -20px;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.shadow-style {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.review-img {
  padding-left: 20px;
  padding-top: 5px;
  width: 70px;

  img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }
}

.horizontal-layout {
  .sidebar,
  .nav-left-menu {
    display: none;
  }
}

.bootstrap-notify-container {
  max-width: 320px;
  text-align: center;
}

.map iframe {
  width: 100%;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #eee;
  color: black;
  font-size: 14px;
  font-family: sans-serif, Verdana;
  padding: 10px;
  pointer-events: none;
}

.logo-white {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #000 !important;
  }
}

.logo-black {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #fff !important;
  }
}

.dark {
  .sidemenu-collapse i {
    color: #fff;
  }

  .nav {
    > li {
      > a {
        color: #fff;
      }
    }
  }
}

.light {
  // .sidemenu-collapse i {
  //   color: #0d091d;
  // }

  .nav {
    > li {
      > a {
        color: #0d091d;
      }
    }
  }
}

.border-apply {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

input:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

textarea:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

.profile-image img {
  width: 100%;
}

@media screen and(max-width: 1169px) {
  // .horizontal-layout {
  //   .sidebar {
  //     display: block;
  //   }

  //   .top-sidebar {
  //     display: none;
  //   }

  //   section.content {
  //     margin-top: 100px;
  //   }
  // }
}

.font-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;

  .icon-preview {
    font-size: 1.8rem;
    margin-right: 10px;
    line-height: 1;
    color: #333439;
  }
}

.deshboard-echart-height {
  height: 250px;
}
.pill-style {
  font-size: 17px;
  color: #a9a6a6;
  padding-right: 5px;
}
.pill-timing {
  width: 30%;
}
.dot1 {
  color: #5a5faf;
}
.dot2 {
  color: #f7bf31;
}
.dot3 {
  color: #ea6e6c;
}
.dot4 {
  color: #28bdb8;
}
@media screen and(max-width: 1169px) {
  // .horizontal-layout {
  //   .sidebar {
  //     display: block;
  //   }

  //   .top-sidebar {
  //     display: none;
  //   }

  //   section.content {
  //     margin-top: 100px;
  //   }
  // }
}

@media screen and(min-width: 1400px) {
  .boxed-layout .container {
    width: 1370px;
    max-width: 100%;
  }
}


.wrap{
  max-width: 400px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%,-30%);
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #fff;
  width: 100%;
  height: 40px;
}
select {
}
.wrap h1{
  text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18, -40px -40px 0px rgba(28,110,164,0);
  color: #181B33;
  background: #232323;
  font-size: 47px;
  text-align: center;
  font-family: monospace;
}
.custom-select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  color: black;
  padding: 7px 15px;
  transition: all 0.2s ease-in;
  border: 1px solid #a49999;
  font-size: 13px;
  border-radius: 5px;
}
// .custom-select:after {
//   content: "";
//   width: 0;
//   height: 0;
//   border: 7px solid transparent;
//   border-color:#9E9E9E transparent transparent transparent;
//   position: absolute;
//   top: 16px;
//   right: 10px;
//   transform: rotateZ(0deg);
//   transition: all 0.5s;
//   transform-origin: 50% 25%;
// }
/*.custom-select:hover {
  background-color: #b83729;
}
*/
.custom-select:active, .custom-select.active {
  background-color: #fff;
  color: #9E9E9E;
}
.custom-select:active:after, .custom-select.active:after {
  transform: rotateZ(180deg);
  transition: all 0.5s;
  transform-origin: 50% 25%;
}
.custom-select:focus{
 outline: 2px;
}
.select-options {
  max-height: 200px;
  overflow-y: auto;
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #e7e6e6;
  border-radius: 0px 0px 10px 10px;
}
.select-options li {
  color: #424242;
  margin: 0;
  padding: 7px 0;
  /* text-indent: 15px; */
  /* border-top: 1px solid #AED581; */
  transition: all 0.15s ease-in;
  padding-left: 15px;
  font-size: 12px;
  // background-color: #847070;
  color: #867373;
  width: 100%;
  border-bottom: 2px solid #eee;
  height: 31px;
  font-weight: 500;
}
.select-options li:hover {
  color: #424242;
  background: #fff;
}
.select-options li[rel="hide"] {
  display: none;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
  border-radius: 0 0 5px 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#9E9E9E;
}
