/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'kaushan';
    font-weight: normal;
    font-style: normal;
    src: url('./assets/fonts/kaushan/KaushanScript-Regular.ttf') format('truetype'); /* Replace with your font file name and format */
  }
  :root {
    // --primary-color: #7dae3c;
    --primary-color: #ffeb04;
    --primary-text-color: #292f36;
    --primary-btn-color:#292f36;
    --primary-btn-text-color:white

  }
  @font-face {
    font-family: 'abel';
    font-weight: normal;
    font-style: normal;
    src: url('./assets/fonts/Abel/Abel-Regular.ttf') format('truetype'); /* Replace with your font file name and format */
  }
  .mat-error {
    color: var(--primary-text-color) !important;
}

// VARIABLES

// CUSTOM FUNCTIONS
@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

// Flexbox display
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline flex display
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: flex-end;
    -moz-box-pack: flex-end;
    -ms-flex-pack: flex-end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}

// nowrap | wrap | wrap-reverse
@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

// flex-start | flex-end | center | baseline | stretch
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}
// row (default) | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

app-file-upload{
  &.hideTable{
    height: auto !important;
  }
}

.content{
  overflow-x: hidden;
}

::ng-deep .mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
  ::ng-deep .mat-dialog-container {
    padding: 0px !important;
   }
   @media screen and (max-width: 767px) {
    .mat-dialog-container {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100vw !important;
    }
    .cdk-overlay-pane{
      max-width: 92vw !important
    }
  }
  @media only screen and (max-width: 992px) {
    button{
      height: 36px !important;
    }  }
  ::ng-deep .icon-button-demo button {
    margin-right: 5px;
    margin-bottom: 12px;
    width: 45px !important;
    height: 45px !important;
}

.edit_icon{
  width: 18px;

}
.edit_icon{

}
.btn_outline{
    background-color: white !important ;
    color: #686b6c !important ;
    border-color: #c2c2c2 !important;
}
::ng-deep .slick-dots{
  bottom: 12px !important
}

::ng-deep .slick-dots li button:before{
  color: white !important;
}

::ng-deep .slick-dots li.slick-active button:before{
  color: white !important;
}
.slick-slider{
  touch-action: auto;
    -ms-touch-action: auto;
}


// CUSTOM STYLE
.custom-tableplaylist{
  tr{
    td{
      .mat-radio-label .mat-radio-label-content {
          display: none;
        }
    }
  }
}
.form_oter{
  min-height: auto;
  padding: 0 !important;
  .user_labele{
    font-weight: 400;
    font-size: 22px;
    margin: 0px;
    color: #272D34;
    text-align: center;
  }
}
app-move-item,app-delete-item {
  width: 100%;
}
td[data-title="Name"]{
.title__ {
    display: flex;
    align-items: center;

    .text__ {
      width: calc(100% - 45px);
    }
  }
}
.title__ {
  display: flex;
  align-items: center;
}
.comingsoondiv{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
      color: #000;
      .heading_com{
        font-size: 62px;
        font-weight: 500;
        color: #07003199;
      }
      p{
        margin: 20px 0;
      }
}
.empty_slots_boxes{
  .slick-arrow {
      background: #fff;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      border: 1px solid #eeee;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .055);
      z-index: 4;
      &::before {
          color: #000;
          background-position: center center;
          background-size: 24px;
          width: 100%;
          height: 100%;
          display: block;
          min-width: 100%;
          float: none;
          position: absolute;
          background-repeat: no-repeat;
      }

      &.slick-next {
        right: -15px;
        &::before{
          content: "";
          background-image: url(assets/images/next.png);
          background-position: 60% 50%;
        }
      }

      &.slick-prev {
        left: -15px;
        &::before {
            content: "";
            background-image: url(assets/images/left.png);
            background-position: 39% 50%;
          }
      }
      &:hover,&:focus{
            background: #fff;
      }
    }
}


.progress_bar .mat-progress-bar-buffer {
  background-color: #ebebebf7;
}
.progress_bar .mat-progress-bar-fill::after {
  background-color: #000;
}
.action_wrap{
  @include flexbox;
  @include align-items(center);
  @include flex-wrap(wrap);
  .dropdown_wrap{
    &:last-child{

      display: flex;
      align-items: center;

    }
    .pointer{
      margin-left: 35px;
    }
  }
}
.dropdown_wrap{
  .dropdown_btn {
    border: 1px solid #F1F1F4;
    border-radius: 8px;
    font-size: 14px !important;
    color: #000000;
    font-weight: 600;
    padding: 2px 22px;
    width: auto;
    height: auto !important;
    .mat-button-wrapper{
      &:after{
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
        content: "";
        display: inline-block;
        margin-left: 16px;
        position: relative;
        top: -1px;
      }
    }
  }
}
.custom_datatable{
  width: 100%;
  .action_wrap{
    margin-left: auto;
  }
  .heading_action{
    @include flexbox;
    @include align-items(center);
    @include flex-wrap(wrap);
  }
  .sub_heading{
    margin-bottom: 6px;
  }
  .mat-sort-header-arrow {
    color: #6B6B6B;
  }
  .mat-sort-header-stem{
    height: 6px;
  }
  .mat-checkbox-inner-container {
    height: 20px;
    width: 20px;
    .mat-checkbox-frame{
      border-radius: 4px;
      border:1px solid #707070;
    }
    .mat-checkbox-background{
      border-radius: 4px;
    }
  }
  .mat-paginator-outer-container{
    margin-top: 20px;
    border-top: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
    .mat-select-min-line{
      position: relative;
      top: 2px;
    }
    .mat-paginator-range-actions{
      position: relative;
      top: -4px;
    }
    .mat-form-field-wrapper{
      padding-bottom:6px;
    }
    .mat-paginator-range-label, .mat-select-min-line, .mat-paginator-page-size-label{
      font-size: 13px;
      color: #333;
      font-weight: 500;
    }
    .mat-form-field-infix{
      border-top: 0px;
    }
    .mat-select{
      border: 1px solid #d5d5d5;
      padding: 12px 12px;
      width: 80px;
      border-radius: 4px;
    }
    .mat-paginator-page-size-select{
      width: 80px;
    }
    .mat-form-field-underline {
      display: none;
    }
  }
  .mat-table {
    .mat-cell{
        img{
            height: 30px;
            max-width: 30px;
            overflow: hidden;
            display: inline-block;
            object-fit: cover;
        }
    }
  }
}
.cdk-cell.cdk-column-name{
  & > div{
    display: flex;
    align-items: center;
    text-align: left;
  }
  a{
    display: flex;
    align-items: center;
    color: #000;
  }
}
mat-radio-button{
  .mat-radio-label{
    .mat-radio-label-content{
      font-size: 16px;
      padding-left: 20px;
    }
    .mat-radio-container{
      .mat-radio-inner-circle{
        background: #414244;
      }
      .mat-radio-outer-circle{
        border-color: #414244 !important;
        &.mat-radio-checked{
          border-color: #414244 !important;
        }
      }
      .mat-ripple {
        .mat-ripple-element {
          background: #414244;
        }
      }
    }
  }
}
.wrap_column_image{
  max-width: 34px;
  margin-right: 30px;
  &:not(.folder_icon) {
    img{
      box-shadow: 0px 30px 60px #00000029;
      border: 4px solid #E1E3E5;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
  img{
    height: auto;
    max-width: 100%;
  }
}
.mat-checkbox-indeterminate.mat-accent, .mat-checkbox-checked.mat-accent{
  .mat-checkbox-background {
    background-color: #292f36;
  }
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
  background: #292f36;
}
.module_wrap{
  font-family: 'Roboto', sans-serif;
  .page_wrap{
    max-width: 1280px;
    padding: 15px 20px 0px;
    margin: 0 auto;
    width: 100%;
    color: #000;
  }
}
button.min_button.button_ht.custom_button{
  background: #fff;
  border: 1px solid #F1F1F4;
  border-radius: 8px;
  opacity: 1;
  box-shadow: none;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500;
  height: auto;
  padding: 3px 29px;
  @include transition(all ease .3s);
  &:hover{
    background: #000;
    color:#fff !important;
  }
  &.canva {
    background:#272D34 url('./assets/images/related_item/canva.svg') left 15px center no-repeat;
    color: #fff !important;
    padding: 5px 17px 5px 50px;
    height: auto !important;
    background-size: 25px;
  }

  &.bg_color {
    background: #000;
    color: #fff !important;
    &[disabled="true"]{
      background: #6c757d;
      opacity: .5;
      cursor: unset !important;
      &:hover{
        background: #6c757d !important;
        color: #fff !important;
      }
    }
    &:hover{
      background: transparent;
      color: #000 !important;
    }
  }
}

.schedule_head{
  app-button{
    margin-right: 15px;
    &:last-child
    {
      margin-right: 0;
    }
  }
  button.min_button.button_ht.custom_button{
    padding: 7px 20px;
    font-size: 16px !important;

  }
}
.recent_items {
  padding: 0px 0px 18px;
  h4{
    font-size: 20px;
    font-weight: 500;
    margin: 0px 0px 22px;
  }
  ul{
    @include flexbox;
    @include align-items(center);
    @include flex-wrap(wrap);
    li{
      width: calc(25% - 18px);
      margin-right: 24px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      @include transition(all ease .3s);
      a{
        color:  rgba(0, 0, 0,.5);
      }
      &:hover{
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        .image_wrap .video_taginner {
            &::before {
              transform: scale(1.1);

            }
          }
      }
      .image_wrap{
        height: 140px;
        overflow: hidden;
        img{
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
        .video_taginner{
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before{
            content: "";
            width: 35px;
            height: 35px;
            background:  url('/assets/images/play.png') no-repeat;
            position: absolute;
            background-size: contain;
            transition: 0.5s;
            transform: scale(1);
          }
        }

      }
      h3{
        background: rgba(239, 239, 240,.9);
        font-size: 14px;
        padding: 15px 15px;
        color: rgba(0,0,0,0.5);
        margin: 0px;
        text-align: center;
        font-weight: 500;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        @include transition(all ease .3s);
      }
      &:nth-child(4n){
        margin-right: 0px;
      }
    }
  }
}
.ng-trigger-transformPanel.mat-select-panel.mat-primary{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 60px #00000029;
    border-radius: 6px !important;
    .mat-option{
      padding: 8px 14px;
      height: auto;
      line-height: normal;
      line-height: initial;
      font-size: 14px !important;
      color: #000;
      display: block;
      width: 100%;
      font-weight: 500;
    }
}
.mat-menu-panel{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 60px #00000029;
  border-radius: 6px !important;
  opacity: 1;
  padding: 22px 0px 0px;
  min-width: 240px !important;
  &.action_menu, &.min-dropdown {
    padding: 10px 0px 0px;
    min-width: 120px !important;
    button.mat-menu-item{
      padding: 11px 20px;
      &:last-child{
        padding: 15px 20px 18px;
      }
    }
  }
  button.mat-menu-item{
    padding:10px 25px;
    height:auto;
    line-height: initial;
    font-size: 16px !important;
    color: #000;
    display: block;
    width: 100%;
    font-weight: 500;
    &:last-child{

      &.delete {
        padding: 15px 25px 18px;
        margin-top: 18px;
        border-top: 1px solid #f7f7f7;
        color: #C90000D3;
      }
    }
  }
}
.cdk-global-overlay-wrapper{
  font-family: 'Roboto', sans-serif;
  .mat-dialog-container{
    padding: 0px;
    border-radius:8px;
  }
  .cdk-overlay-pane{
    width: 100% !important;
    max-width: 715px !important;
    box-shadow: 0px 15px 60px #00000029;
    border-radius: 8px;
    @include transition(all ease .3s);
    overflow-y: auto;
    max-height: 82vh;
    &.increased_width{
      max-width: 972px !important;
    }
    &.menu_open{
      .button_wrap{
        & > div:first-child{
            // margin-right: 0px;
        }
      }
    }
    .addContainer{
      &.hasSearchBar{
        .popup_heading{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }
        .mat-form-field-infix{
              width: 100%;
              border: none;
              padding: 0;
              padding-right: 52px;
              input {
                  background: #F5F7F9 url('./assets/images/search_icon.png') left 9px top 12px no-repeat;
                  padding: 15px 22px 15px 35px;
                  border-radius: 8px;
                  background-size: 18px;
                  height: auto;
                  font-size: 14px;
                  letter-spacing: 0px;
                  color: rgba(0, 0, 0, .47);
                  font-weight: 500;
                  max-width: 450px;
                  width: 100%;
                  box-sizing: border-box;
                  text-align: left;
                  font-family: 'Roboto', sans-serif;
                }
                .mat-form-field-label-wrapper{
                  label.mat-form-field-label{
                      padding-left: 35px;
                      top: 26px;
                  }
                }
        }
        .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
          transform: translateY(-26px) scale(0.75) perspective(100px) translateZ(0.001px);
          padding-left: 0;
        }
        .mat-form-field-appearance-legacy .mat-form-field-underline{
          display: none;
        }
        .mat-form-field-wrapper{
          padding: 0;
        }
        .form{
          background: transparent;
          padding-top: 0;
        }
      }
    }
    .popup_heading{
      .grey_bar{
        height: 53px;
        background: #EAEBEB;
        border-radius: 8px 8px 0px 0px;
        width: 100%;
      }
      h2{
        padding: 0px 52px;
        padding: 34px 52px 29px;
        font-size: 20px;
        color: #000;
        word-break: break-all;
      }
      &.showSearchbar{
        display: flex;
        flex-wrap: wrap;
        .grey_bar{
          margin-bottom: 8px;
        }
        .search_form{
          margin-left: auto;
          width: 310px;
          padding-right: 52px;
          .mat-form-field-underline {
            display: none;
          }
          app-search, mat-form-field{
            width: 100%;
            display: block;
          }
          .mat-form-field-label{
            left: 55px;
            top: 32px;
          }
          .mat-focused{
            .mat-form-field-label-wrapper{
              opacity: 0;
            }
          }
          .mat-form-field-wrapper {
            padding-bottom: 0px;
            position: relative;
            top: 7px;
          }
          input{
              background: #F5F7F9 url('/assets/images/search_icon.png') left 21px top 11px no-repeat;
              padding: 14px 22px 14px 52px;
              border-radius: 8px;
              background-size: 17px;
              height: auto;
              font-size: 14px;
              letter-spacing: 0px;
              color: #6B6B6B;
              font-weight: 400;
              border: 1px solid #DDE2E6;
              max-width: 285px;
              width: 100%;
              box-sizing: border-box;
              text-align: left;
              &::-webkit-input-placeholder {
                  color: #6B6B6B;
                  font-size: 14px !important;
                  font-weight: 400;
              }
              &:-ms-input-placeholder {
                  color: #6B6B6B;
                  font-size: 14px !important;
                  font-weight: 400;
              }
              &::placeholder {
                  color: #6B6B6B;
                  font-size: 14px !important;
                  font-weight: 400;
              }
          }
        }
      }
    }
    .form{
      padding: 43px 52px;
      background: rgba(227,229,230,.35);
      p{
        font-size:22px;
        margin: 0px;
        color: #272D34;
        text-align: center;
      }
      input{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #000000;
        border-radius: 8px;
        color: rgba(39, 45, 52,.9);
        font-size: 16px !important;
        padding: 12px 20px;
        width: 100%;
        &::-webkit-input-placeholder {
          color: rgba(39, 45, 52,.5);
          font-size: 16px !important;
        }
        &:-ms-input-placeholder {
          color: rgba(39, 45, 52,.5);
          font-size: 16px !important;
        }
        &::placeholder {
          color: rgba(39, 45, 52,.5);
          font-size: 16px !important;
        }
      }
    }
    .button_wrap{
      padding: 25px 52px;
      @include flexbox;
      @include align-items(center);
      @include justify-content(flex-end);
      &.add_screen_btn{
        justify-content: flex-start;
        & > div{
          &:first-child{
            margin-right: auto;
          }
        }
      }
      button{
        margin-right: 37px;
        font-size: 16px !important;
        line-height: 25px;
        padding: 0;
        span{
          padding: 6px 22px;
          display: block;
        }
        &.bg_color {
          background-color: #000;
          color: #fff !important;
        }
        &.mat-warn{
          font-size: 16px !important;
          line-height: 25px;
          height: auto;
          border-radius: 8px;
        }
        &:last-child{
          margin-right: 0px;
        }
      }
      & > div{
        margin-left: 37px;
        &:first-child{
          margin-left: 0px;
        }
      }
    }
  }
}
.increased_width_lg{

  .cdk-overlay-dark-backdrop {
      background: rgba(0, 0, 0, .56);
  }
  .cdk-global-overlay-wrapper{
     .mat-dialog-container{
      background: transparent;
     }

   .cdk-overlay-pane {
    box-shadow: none;
    .popup_heading{
      display: none;
    }
    .form{
      padding: 0 0;
      background: transparent !important;
      padding-bottom: 0 !important;
      app-preview-playlist{
        margin: 0 0 0 0;
         .popup_preview {
          .current_layout{
            width: 100%;
            border: 30px solid #fff;
            border-radius: 25px;
            >div{
              padding: 0 0;
              border: none;
              background: #000;
              .items{
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
              }
            }
          }
          .Verticle_Center_Pane_\+_Four_Square{
              max-width: 450px;
              min-height: 80vh;
              >div {
                  width: 50%;
                  height: 33.33%;
                  &:nth-child(3){
                    width: 100%;
                  }
              }
                .slick-slider {
                    height: 100%;

                    .slick-list {
                      height: 100%;

                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
            .items{
              height: 100%;
              overflow: hidden;

            }
          }
          .Verticle_Main{
            max-width: 450px;
            height: 80vh;
            .slick-slider{
              height: 100%;
              .slick-list{
                height: 100%;
                .slick-track{
                  height: 100%;
                }
              }
            }
            .items{
              height: 100%;
            }
          }
          .Verticle_Triple{
            max-width: 450px;
            height: 80vh;
            > div{
                width: 100%;
                height: 33.33%;
                .slick-slider {
                    height: 100%;

                    .slick-list {
                      height: 100%;

                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
            }
            .items {
              height: 100%;
            }
          }
          .Verticle_Split {
            max-width: 450px;
            height: 80vh;
            overflow: hidden;
            > div{
                width: 100%;
                height: 50%;
                .slick-slider{
                  height: 100%;
                  .slick-list{
                    height: 100%;
                    .slick-track{
                      height: 100%;
                    }
                  }
                }
            }
            .items {
              height: 100%;
            }
          }
          .Verticle_Four_Pane{
            height: 80vh;
            max-width: 450px;
            overflow: hidden;
            >div {
                width: 50%;
                height: 50%;

                .slick-slider {
                  height: 100%;

                  .slick-list {
                    height: 100%;

                    .slick-track {
                      height: 100%;
                    }
                  }
                }
              }
            .items{
              height: 100%;
            }
          }
          .Verticle_Main_\+_Footer{
              height: 80vh;
              max-width: 450px;
              overflow: hidden;
              >div{
                height: 80%;
                width: 100%;
                .slick-slider {
                    height: 100%;

                    .slick-list {
                      height: 100%;

                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
                &:last-child{
                  height: 20%;
                }
              }
            .items {
              height: 100%;
              width: 100%;
            }

          }
          .Verticle_Main_\+_Header{
              height: 80vh;
              max-width: 450px;
              overflow: hidden;
            >div {
                height: 20%;
                width: 100%;

                .slick-slider {
                  height: 100%;

                  .slick-list {
                    height: 100%;

                    .slick-track {
                      height: 100%;
                    }
                  }
                }

                &:last-child {
                  height: 80%;
                }
              }

              .items {
                height: 100%;
                width: 100%;
              }
          }
          .Verticle_Main_\+_Header_\+_Footer{
              height: 80vh;
              max-width: 450px;
             >div{
              width: 100%;
              height: 60%;
                .slick-slider {
                    height: 100%;
                    .slick-list {
                      height: 100%;
                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
              &:last-child,&:first-child{
                height: 20%;
              }
             }
              .items {
                height: 100%;
                width: 100%;
              }
          }
          .Horizontal_Main{
            height: 60vh;
            max-width: 900px;
            overflow: hidden;
            .slick-slider {
                height: 100%;

                .slick-list {
                  height: 100%;

                  .slick-track {
                    height: 100%;
                  }
                }
              }
            .items{
              width: 100%;
              height: 100%;
            }
          }
          .Horizontal_Split{
            height: 60vh;
            max-width: 900px;
            overflow: hidden;
            >div{
              width: 100%;
              height: 50%;
              .slick-slider {
                  height: 100%;
                  .slick-list {
                    height: 100%;
                    .slick-track {
                      height: 100%;
                    }
                  }
                }
            }
            .items{
              height: 100%;
              width: 100%;
            }
          }
          .Horizontal_Triple{
              height: 60vh;
              max-width: 900px;
              overflow: hidden;

              >div {
                width: 33.33%;
                height: 100%;

                .slick-slider {
                  height: 100%;

                  .slick-list {
                    height: 100%;

                    .slick-track {
                      height: 100%;
                    }
                  }
                }
              }

              .items {
                height: 100%;
                width: 100%;
              }
          }
          .Horizontal_Four_Pane{
            height: 60vh;
            max-width: 900px;
            overflow: hidden;
              >div {
                  width: 50%;
                  height: 50%;

                  .slick-slider {
                    height: 100%;

                    .slick-list {
                      height: 100%;

                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
                }

                .items {
                  height: 100%;
                  width: 100%;
                }
          }
          .Horizontal_Center_Pane_\+_Four_Square{
              height: 60vh;
              max-width: 900px;
              overflow: hidden;
              flex-wrap: wrap !important;
              flex-flow: unset;
              align-items: center;
              justify-content: center;
              position: relative;
            >div{
                  width: 33.33%;
                  height: 50%;
                  &:nth-child(1),&:nth-child(3){
                        margin-right: 33.33%;
                  }
                  &:last-child{
                        position: absolute;
                        height: 100%;
                        top: 0;
                        width: 33.33%;
                        height: 100%;
                        .items {
                            height: 100%;
                        }
                  }
                  .slick-slider {
                    height: 100%;

                    .slick-list {
                      height: 100%;

                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
            }
            .items{
              height: 100%;
              width: 100%;
            }
          }
          .Horizontal_Main_\+_Header{
              height: 60vh;
              max-width: 900px;
              overflow: hidden;
              > div{
                width: 70%;
                height: 100%;
                .slick-slider {
                    height: 100%;
                    .slick-list {
                      height: 100%;
                      .slick-track {
                        height: 100%;
                      }
                    }
                  }
                &:first-child{
                  width: 30%;
                }
              }
              .items {
                height: 100%;
                width: 100%;
              }
          }
          .Horizontal_Main_\+_Footer {
            height: 60vh;
            max-width: 900px;
            overflow: hidden;

            >div {
              width: 30%;
              height: 100%;

              .slick-slider {
                height: 100%;

                .slick-list {
                  height: 100%;

                  .slick-track {
                    height: 100%;
                  }
                }
              }

              &:first-child {
                width: 70%;
              }
            }

            .items {
              height: 100%;
              width: 100%;
            }
          }

          .current_layout{
            .items{
              video{
              }
            }
          }
         }
      }
    }
   }
  }
}
.increased_width{
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .form{
        padding: 42px 52px 55px 43px;
      }
    }
  }
}
app-recent-item-popup{
  width: 100%;
  .playlist_form{
    display: none;
  }
  .menu_popup_content{
    @include align-items(center);
    .menu_item{
      width: 290px;
      border-radius: 8px;
      overflow:hidden;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
    }
    .image_wrap{
      height: 240px;
      overflow: hidden;
      img{
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        -o-object-fit: cover;
        object-position: top center;
      }
    }
    h4{
      // background: #EFEFF0;
      font-size: 14px;
      padding: 0;
      margin: 15px 15px;
      text-align: center;
      font-weight: 500;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      transition: all ease 0.3s;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .right{
      padding-left: 65px;
      width: calc(100% - 290px);
      text-align:right;
      button:not(.select2-selection__choice__remove){
        margin-bottom: 26px;
        min-width: 173px !important;

        &:not(.canva){
          background:#fff url('./assets/images/related_item/playlist_icon.png') left 15px center no-repeat;
          background-size: 21px !important;
          padding: 4px 17px 4px 36px;
          border: 1px solid #D6D6D6;
          font-weight: 500;
          &:hover{
            background:#fff url('./assets/images/related_item/playlist_icon.png') left 15px center no-repeat;
            background-size: 21px;
            color: #000 !important;
          }
        }

      }
      .showbtnlist {
        button {
          background: #fff url('./assets/images/related_item/showplaylist.png') left 15px center no-repeat !important;
        }
      }
      app-button{
        &:last-child{
          button{
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .playlist_form {
    text-align: left;
    margin-top:25px;
    .form_control{
      @include flexbox;
      @include align-items(center);
      @include flex-wrap(wrap);
      margin-bottom:38px;
      &:last-child{
        margin-bottom: 0px;
      }
    }
    label, input, .select2 , select{
      width: 100% !important;
      display: block;
    }
    select {
      width: calc(100% - 92px) !important;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      padding: 4px 18px;
      min-height: 50px;
      overflow: hidden;
      appearance: none;
      font-size: 12px !important;
    }
    .select2{
      width: calc(100% - 92px) !important;
      .select2-search.select2-search--inline, textarea{
        width: 100% !important;
        background: #fff;
        display: none !important;
      }
    }
    .form_control{
      .select2-selection.select2-selection--multiple {
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, .4);
          border-radius: 8px;
          padding: 4px 10px;
          min-height: 50px;
          overflow: hidden;

          &:after {
            content: "Select Playlists";
            font-size: 12px;
            color: #000;
            position: absolute;
            left: 15px;
            top: 25px;
            transform: translateY(-50%);
          }

          .select2-selection__choice {
            background: #000;
            border: 0px;
            border-radius: 4px;
            color: #000;
            @include flexbox;
            padding: 7px 13px 7px 17px;
            margin-right: 13px;
            position: relative;
            z-index: 1111;

            button {
              order: 2;
              background: transparent;
              border: 1px solid #fff;
              margin-left: 15px;
              border-radius: 100%;
              width: 15px;
              height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              position: initial;
            }

            span {
              order: 1;
              color: #fff;
              font-size: 10px;
              color: #FFFFFF;
            }
          }
        }
      &.zoneListmain{
         .select2-selection.select2-selection--multiple {
            &:after{
              content: "Select Zone Lists";
            }
         }

      }
    }

    label{
      font-size:16px;
      font-weight: 500;
      color: #272D34;
      width: 92px !important;
      margin-bottom:0px;
    }
    .time_wrap{
      @include flexbox;
      @include align-items(center);
      .time{
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,.4);
        border-radius: 8px;
        padding: 11px 35px;
        text-align: center;
        font-size:16px;
        font-weight: 500;
        color: #272D34;
        margin-right: 20px;
      }
      span{
        font-size:16px;
        font-weight: 500;
        color: #272D34;
        width: 92px !important;
        margin-bottom:0px;
      }
    }
  }
}
app-header{
  .navbar {
    .search_box_wrap{
      float: left;
      width: calc(100% - 378px);
      text-align: center;
      @include flexbox;
      @include align-items(center);
      // max-width: 1266px;
      // margin: 0 auto;
      app-search{
        width: 100%;
        max-width: 450px;
        margin-right: 24px;
        .mat-form-field-wrapper{
          padding-bottom: 13px;
          padding-top:1px;
        }
        .mat-focused{
          .mat-form-field-label-wrapper{
            opacity: 0;
          }
        }
        .mat-form-field-underline, .mat-form-field-subscript-wrapper{
          display: none;
        }
        .mat-form-field-infix{
          padding: 0px;
        }
        .mat-form-field{
          width: 100%;
          text-align:center;
        }
        .mat-form-field-label-wrapper{
          max-width: 450px;
          margin: 0 auto;
          padding-bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          top: -4px;
          text-align: left;
          .mat-form-field-label{
            padding-left:60px;
            font-size: 14px;
            color: rgba(0,0,0,.47);
            font-weight: 500;
          }
        }
        input{
          background: #F5F7F9 url('./assets/images/search_icon.png') left 22px top 11px no-repeat;
          padding: 15px 22px 15px 58px;
          border-radius: 8px;
          background-size:18px;
          height: auto;
          font-size: 14px;
          letter-spacing: 0px;
          color: rgba(0,0,0,.47);
          font-weight: 500;
          max-width: 450px;
          width: 100%;
          box-sizing: border-box;
          text-align: left;
          font-family: 'Roboto', sans-serif;
          &:focus{
            & + .mat-form-field-label-wrapperspan{
              display: none;
            }
          }
        }
      }
    }
  }
}
app-rename-item, app-folder-popup{
  width: 100%;
}
.sub_heading{
  font-size: 20px;
  font-weight: 500;
}
.mat-checkbox-layout{
  margin-bottom: 0px;
}

.right_bar{
  .create_playlist_btn {
      .custom_button.bg_color{
          background: #000;
          color: #fff !important;
          &:hover{
            background: transparent;
            color: #000 !important;
          }
      }
  }
}
.new_schedule_body{
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .popup_heading {
        h2{
          padding-bottom: 10px;
        }
      }
    }
    .cdk-overlay-pane {
      .form{
        background: #fff;
        padding-top: 0px;
        padding-bottom: 10px;
      }
    }
  }
  .selection_datatable {
    mat-row{
      &.selected{
        border: 1px solid rgba(112, 112, 112, .27) !important;
      }
    }
    mat-row, mat-footer-row{
      margin-bottom: 19px;
    }
    .mat-header-cell:last-child, mat-cell:last-child{
      flex: 0 0 150px;
      width: 150px;
    }
  }

  .content.module_wrap{
    margin-right: 0px;
    .content-block{
      padding: 0px;
      & > app-wrapper{
        .row{
          & > div{
            & > .card{
              & > .body{
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}
.fc.fc-theme-standard{
    .fc-dayGridMonth-view{
      .fc-event {
        display: block;
      }
      .fc-scroller-harness{
        .fc-col-header{
          .fc-col-header-cell{
            .fc-col-header-cell-cushion{
              display: none;
            }
          }
        }
        .fc-daygrid-body{

          .fc-day{
            .fc-daygrid-day-top{
              a{
                display: block;
                text-align: center;
                width: 100%;
                color: #292F36;
              }
            }
          }
        }
      }
    }
    .fc-h-event, .fc-event {
      background-color: transparent;
      border: 0px;
      display: inline-grid;
      box-shadow:none;
      .custom-event{
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        .button_event_wrap{
          background: #FFFFFF;
          border-radius: 19px;
          padding: 5px 16px 5px 18px;
          display: flex;
          align-items: center;
          margin-top: 5px;
          button{
            &:first-child{
              border: 0px;
              background: url('./assets/images/delete_button.png') center center no-repeat;
              width: 15px;
              height: 16px;
              background-size: contain;
              margin-right: auto;
              cursor: pointer;
            }
          }
          button{
            border: 0px;
            background: transparent;
            outline: 0px;
            font-size: 13px !important;
            color: #292F36;
            font-weight: 500;
          }
        }
        & > div:not(.button_event_wrap){
          padding: 18px 18px 20px;
          background: #FFFFFF;
          border-radius: 8px;
          margin-top: 5px;
          height: calc(100% - 41px);
          .event-name{
            color: #292F36;
            font-size: 16px;
            font-weight: 500;
            margin: 0px 0px 8px;
          }
          .start_end_time{
            opacity: 1;
            color: #292F36;
            font-size: 12px;
            font-weight: 400;
          }
        }
        &:hover{
          &>div:not(.button_event_wrap) {
            .start_end_time {
                opacity: 1;
              }
          }

        }
      }
      &.fc-event-end.fc-event-past {
        .fc-event-main {
          .custom-event {
            &>div:not(.button_event_wrap) {
              // padding: 18px 18px 55px;
            }
          }
        }
      }
    }
    .fc-day-today{
      background: #F3F5F9 !important;
    }
    td, th{
      border:2px solid #fff;
    }
    .fc-timegrid-slot.fc-scrollgrid-shrink{
      position: relative;
      top: 17px;
    }
    .fc-timegrid-slot.fc-scrollgrid-shrink, .fc-timegrid-axis-frame.fc-scrollgrid-shrink-frame{
      padding: 2px 20px;
      text-align: center;
      font-size: 12px;
      color: #292F36;
      font-weight: 600;
      border-top: 2px solid #F3F5F9 !important;
      border-bottom: 2px solid #F3F5F9 !important;
    }
    .fc-timegrid-slot.fc-timegrid-slot-lane{
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .fc-view-harness{
      height: calc(100vh - 300px) !important;
      .fc-scrollgrid{
        .fc-scrollgrid-section:first-child{
          & > td{
            border-top: 0px solid #fff;
            border-bottom: 0;
            table.fc-scrollgrid-sync-table{
              tbody{
                td{
                  .fc-timegrid-axis {
                    border: 0px !important;
                  }
                }
              }
            }
          }
        }
        .fc-scrollgrid-section:nth-child(2){
          display: none;
        }
      }
    }
    .fc-header-toolbar.fc-toolbar {
      background: #f3f5f9;
      margin-bottom: 0px;
      padding: 20px 20px 20px;
      border-bottom: 1px solid #fff;
      .fc-toolbar-chunk{
        button{
          background: transparent;
          border-color: #ccc;
          color: #000;
          text-transform: capitalize;
          transition: all ease .3s;
          &.fc-button-active, &:hover{
            color: #fff;
            background: #000;
          }
        }
      }
      .fc-toolbar-title{
        font-size: 20px;
        color: #292F36;
      }
      .fc-toolbar-chunk{
        &:first-child{
          display: none;
        }
        .fc-today-button.fc-button{
          display: none;
        }
      }
    }
    .fc-scrollgrid-section-header {
      .fc-col-header {
        .fc-scrollgrid-sync-inner{
          padding: 19px 0px 14px;
          text-align: center;
          position: relative;
          a{
            font-size: 15px !important;
            color: #292F36;
            opacity: .8;
            display: block;
            width: 100%;
            margin: 7px 0px 0px;
          }
        }
        .fc-col-header-cell{
          .fc-scrollgrid-sync-inner{
            &::before{
              content: 'S';
              background: #868686;
              width: 29px;
              height: 29px;
              border-radius: 100px;
              font-size: 12px;
              color: #fff;
              text-align: center;
              line-height: 29px;
              display: inline-block;
            }
          }
          &.fc-day-mon{
            .fc-scrollgrid-sync-inner{
              &::before{
                content: 'M';
              }
            }
          }
          &.fc-day-tue{
            .fc-scrollgrid-sync-inner{
              &::before{
                content: 'T';
              }
            }
          }
          &.fc-day-wed {
            .fc-scrollgrid-sync-inner{
              &::before{
                content: 'W';
              }
            }
          }
          &.fc-day-thu {
            .fc-scrollgrid-sync-inner{
              &::before{
                content: 'T';
              }
            }
          }
          &.fc-day-fri  {
            .fc-scrollgrid-sync-inner{
              &::before{
                content: 'F';
              }
            }
          }
          &.fc-day-sat  {
            .fc-scrollgrid-sync-inner{
              &::before{
                content: 'S';
              }
            }
          }
        }
      }
      .fc-scroller-harness{
        table{
          th, td{
            border: 0px;
          }
        }
      }
    }
    .fc-scrollgrid{
      border: 0px;
      background: #F3F5F9;
      & > thead{
        & > tr{
          & > th{
            border: 0px;
          }
        }
      }
      & > tbody{
        & > tr{
          & > td{
            border: 0px;
          }
          &:first-child{
            & > td{
              border-top: 2px solid #fff !important;
              border-bottom: 2px solid #fff !important;
            }
          }
        }
      }

    }

}
.fc-daygrid-body {
  .fc-daygrid-day-frame {
    min-height: 100%;
    position: relative;
    max-height: 172px;
    overflow-y: auto;
  }
}
.mat-calendar{
  .mat-calendar-header  {
    padding: 0px;
    .mat-calendar-controls{
      padding: 10px 14px 12px;
      margin: 0px 0px 24px;
      border-bottom: 1px solid #D6D6D6;
    }
  }
}
.mat-calendar-content{
  padding: 0 24px !important;
  .mat-calendar-table-header-divider{
    display: none;
  }
  .mat-calendar-table-header {
    span{
      font-weight: 600;
      color: #333;
      font-size: 12px;
    }
  }
  .mat-calendar-body{
    .mat-calendar-body-cell-content{
      color: rgba(0,0,0,.5);
      font-weight: 500;
    }
    .mat-calendar-body-label{
      font-weight: 800;
      color: #333;
    }
    .mat-calendar-body-cell-content{
        width: 80% !important;
        height: 80% !important;
    }
    .mat-calendar-body-today.mat-calendar-body-selected, .mat-calendar-body-selected{
      box-shadow: none;
      background-color: #000;
      color: #fff !important;
      width: 80% !important;
      height: 80% !important;
      line-height: 31px !important;
    }
  }
}
body.increased_width{
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane{
      max-width: 972px !important;
      overflow-y:auto;
    }
  }
}
._logosuccess {
  margin: 15px 0 !important;
}

.new_schedule_mob_form, .custom_form_style {
  max-width: 650px;
  margin: 0 auto;
  width: 100%;
  padding: 0px;
  &.custom_form_style{
    padding: 15px 0px 20px;
  }
  .form_control, .form-group{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 34px;
    width: 100%;
    .flex_ends{
      width: 100%;
    }
    &.checkbox_group{
      width: calc(100% - 155px) !important;
      margin-left: auto;
      flex-wrap: nowrap;
      .mat-checkbox{
        width: 17.28%;
        padding-right: 10px;
        margin: 0px;
        .mat-checkbox-layout{
          width: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-right: 0px;
          .mat-checkbox-label{
            order: 1;
            margin-bottom: 7px;
            width: 100%;
            display: block;
            text-align: center;
            color: #272D34;
            font-size: 16px;
            font-weight: 600;
          }
          .mat-checkbox-inner-container{
            order: 2;
            margin: 0px;
          }
        }
        &:last-child{
          padding-right: 0px;
        }
      }
    }
    .mat-form-field-type-mat-select{
      .mat-form-field-infix{
        padding-bottom: 0px;
      }
      .mat-form-field-wrapper{
        padding-bottom: 0px;
      }
      &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float{
        .mat-form-field-label{
          display: none;
        }
      }
      .mat-select-value-text{
        color: #272D34;
        opacity: 0.7;
        font-size: 16px;
        font-weight: 500;
      }
      mat-select{
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,.4);
        border-radius: 8px;
        padding: 15px 16px 15px 25px;
        font-size: 16px;
        font-weight: 500;
      }
      .mat-select-arrow-wrapper{
        .mat-select-arrow {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid;
            color: #202429;
        }
      }
      .mat-form-field-label-wrapper{
        // display: none;
        label{
          color: #272D34;
          opacity: 0.7;
          font-size: 16px;
          font-weight: 500;
          width: 100%;
          padding: 13px 0px 0px 24px;
          margin-right: 0px;
        }
      }
      .mat-form-field-underline {
        display: none;
      }
    }
    &:last-child{
      margin-bottom: 0px;
    }
    label{
      font-size: 16px;
      color: #272D34;
      font-weight: 500;
      margin-right: 25px;
      width: 130px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .mat-form-field{
      width: calc(100% - 165px);
    }
    input{
      width: calc(100% - 155px) !important;
      color: rgba(39, 45, 52, .7) !important;
      font-size: 16px;
      font-weight: 500;
      border: 1px solid rgba(0,0,0,.4) !important;
    }
    .select {
      .custom-select{
        padding: 24px 20px;
        height: auto;
        line-height: 0px;
        color: rgba(39, 45, 52, .6) !important;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid rgba(0,0,0,.4) !important;
      }
    }
    div.wd_70{
      width: calc(100% - 155px) !important;
      input{
        width: 100% !important;
        padding: 24px 20px !important;
      }
    }
  }
}
.select_orientation{
  mat-radio-button{
      .mat-radio-label{
          border: 1px solid #707070;
          border-radius: 8px;
          padding: 15px 75px 15px 26px;
          .mat-radio-label-content{
            font-size: 20px;
            font-weight: 500;
            padding-left: 20px;
            color: #000;
          }
          .mat-radio-container{
            .mat-radio-outer-circle{
              border-color: #707070;
              border-width: 1px;
            }
            .mat-radio-inner-circle{
              background-color: #292F36;
              transform: scale(0.7);
            }
            .mat-ripple {
              .mat-ripple-element{
                background: #292F36;
              }
            }
          }
      }
      margin-left: 20px;
      &:first-child{
        margin-left: 0px;
      }
  }
}
.activeButton {
  button.min_button.button_ht.custom_button {
    background-color: #000 !important;
    color: #fff !important;
  }
}
.createnewfolder{
  button{
    padding: 0 !important;
    span{
      padding: 3px 29px;
      width: 100%;
      display: block;
    }
  }
}
button{
  cursor: pointer !important;
}
.buttons_with_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .materialTableHeader,
  button {
    margin-bottom: 15px;
  }
}
.input2 {
  button.min_button.button_ht.custom_button {
    height: 50px;
  }
}
.input {

  .select2-selection.select2-selection--multiple {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 8px;
    padding: 4px 10px;
    min-height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 50px;
    -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 99%;
      background-position-y: 12px;
    .select2-selection__rendered{
      margin-left: -15px;
      height: 50px;
      overflow: auto;
      padding-top: 8px;
      li{

      }
    }
    .select2-search--inline{
      order: -1;
      color: #000;
    }
    .select2-search--inline .select2-search__field{
      display: flex;
      margin-top: 0px;
      color: #000 !important;
      &::placeholder{
        color: #000;
      }
    }
    .select2-selection__choice {
      background: #000;
      border: 0px;
      border-radius: 4px;
      color: #000;
      display: flex;
      padding: 5px 5px 5px 5px;
      margin-right: 0;
      position: relative;
      z-index: 1111;

      button {
        order: 2;
        background: transparent;
        border: 1px solid #fff;
        margin-left: 0px;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: initial;
      }

      span {
        order: 1;
        color: #fff;
        font-size: 10px;
        color: #FFFFFF;
        padding-left: 0;
      }
    }
  }
}
app-preview-playlist {
  width: 100%;
  margin: 20px 0px 0px;

  .popup_preview {
    width: 100%;

    .current_layout {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &>div {
        border: 1px solid #ddd;
        background: #f7f7f7;
        border-radius: 0px;
        padding: 50px 20px;
        text-align: center;

        span {
          font-size: 20px;
          font-weight: 600;
          margin: 0px 0px 20px;
          display: block;
        }
      }

      &.Verticle_Split {
        &>div {
          width: 100%;

          &:nth-child(2) {
            border-top: 0px;
          }
        }
      }

      &.Verticle_Main {
        &>div {
          width: 100%;
          padding: 100px 20px;
        }
      }

      &.Verticle_Triple {
        &>div {
          width: 100%;

          &:nth-child(2),
          &:nth-child(3) {
            border-top: 0px;
          }
        }
      }

      &.Verticle_Four_Pane {
        &>div {
          width: 50%;

          &:nth-child(2n) {
            border-left: 0px;
          }

          &:nth-child(3),
          &:nth-child(4) {
            border-top: 0px;
          }
        }
      }

      &.Verticle_Center_Pane_\+_Four_Square {
        &>div {
          width: 50%;

          &:nth-child(2),
          &:nth-child(5) {
            border-left: 0px;
          }

          &:nth-child(3) {
            border-top: 0px;
            width: 100%;
            border-bottom: 0px;
          }
        }
      }

      &.Verticle_Main_\+_Footer {
        &>div {
          width: 100%;
          padding: 150px 20px;

          &:nth-child(2) {
            padding: 30px 20px;
            border-top: 0px;
          }
        }
      }

      &.Verticle_Main_\+_Header {
        &>div {
          width: 100%;
          padding: 150px 20px;

          &:first-child {
            padding: 30px 20px;
            border-bottom: 0px;
          }
        }
      }

      &.Verticle_Main_\+_Header_\+_Footer {
        &>div {
          width: 100%;
          padding: 150px 20px;

          &:first-child {
            padding: 30px 20px;
            border-bottom: 0px;
          }

          &:last-child {
            padding: 30px 20px;
            border-top: 0px;
          }
        }
      }

      &.Horizontal_Main {
        &>div {
          width: 100%;
          padding: 50px 20px;
        }
      }

      &.Horizontal_Split {
        &>div {
          width: 100%;

          &:nth-child(2) {
            border-top: 0px;
          }
        }
      }

      &.Horizontal_Triple {
        &>div {
          width: 33.33%;

          &:nth-child(2) {
            border-left: 0px;
            border-right: 0px;
          }
        }
      }

      &.Horizontal_Four_Pane {
        &>div {
          width: 50%;

          &:nth-child(2n) {
            border-left: 0px;
          }

          &:nth-child(3),
          &:nth-child(4) {
            border-top: 0px;
          }
        }
      }

      &.Horizontal_Center_Pane_\+_Four_Square {
        flex-flow: column wrap;
        height: 250px;

        &>div {
          width: 33.33%;

          &:last-child {
            order: 3;
            height: 245px;
            border-left: 0px;
            border-right: 0px;
          }

          &:nth-child(3) {
            order: 4;
          }

          &:nth-child(4) {
            order: 5;
          }

          &:first-child,
          &:nth-child(3) {
            border-bottom: 0px;
          }
        }
      }

      &.Horizontal_Main_\+_Header {
        &>div {
          width: 75%;

          &:first-child {
            width: 25%;
            border-right: 0px;
          }
        }
      }

      &.Horizontal_Main_\+_Footer {
        &>div {
          width: 25%;

          &:first-child {
            width: 75%;
            border-right: 0px;
          }
        }
      }
    }
  }
}
body.increased_width_lg {
  .closebtnpopup{
      position: absolute;
      right: -12px;
      top: -12px;
      z-index: 9;
      app-button{
        button.min_button.button_ht.custom_button{
            background: #fff;
            border: none;
            width: 22px;
            height: 22px !important;
            border-radius: 100%;
            padding: 0;
            max-width: 22px;
            min-width: 22px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before{
              content: "";
              position: absolute;
              background-image: url('./assets/images/clos.png');
              background-position: center center;
              background-size: 10px;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
            }

          .mat-button-wrapper{
            font-size: 18px;
            font-weight: 800;
            color: #D6D6D6;
          }
          .mat-button-focus-overlay{
            opacity: 1;
              display: none;
          }
        }
      }
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      max-width: 900px !important;
      overflow: visible;
      width: fit-content !important;
      display: flex;
      justify-content: center;

      .mat-dialog-container{
        overflow: visible;
        width: fit-content !important;
        box-shadow: none;
        .addContainer{
          overflow: unset;
          position: relative;
          .popup_preview{
              width: 100%;
              display: flex;
              justify-content: center;
          }
        }
      }
    }
  }

  .cdk-overlay-container {

    .cdk-overlay-backdrop,
    .cdk-global-overlay-wrapper {
      display: none;

      &:last-child {
        display: flex;
      }
    }

    .cdk-overlay-backdrop {
      &:nth-last-child(2) {
        display: block;
      }
    }
  }
}
.clearall_ {
  button.min_button.button_ht.custom_button {

    background: transparent;
    margin-top: 15px;
    padding: 0;
    min-width: auto !important;
    line-height: 1.2;
    color: #000 !important;
    .mat-button-focus-overlay{
      display: none;
    }
  }
}
.cdk-global-overlay-wrapper {
  .cdk-overlay-pane{
    .addContainer {
        &.playlistpop,&.screenselectionpop {
          .popup_heading {
            .grey_bar{
              order: 0;
            }
            h2{
              order: 1;
              width: 100%;
              padding: 25px 51px 29px;
              padding-bottom: 0;
              margin-bottom: 0;
            }
            .search_form{
              order: 2;
              width: 100%;
              padding: 15px 52px;
              mat-form-field{
                width: 100%;
                .mat-form-field-infix{
                  padding-right: 0;
                  input{
                    max-width: 100%;
                  }
                }

              }
            }
          }
          .desktop_view{
            mat-table{
              height: 53vh;
              overflow-y: auto;
              width: 100%;
              display: block;
            }
          }
          .form{
            padding-bottom: 0;
          }
        }
      }
  }
}
// .disabledstart{
//   button.min_button.button_ht.custom_button.bg_color{
//       background-color: #6c757d !important;
//       background: #6c757d;
//       opacity: 0.5;
//       cursor: unset !important;
//       pointer-events: none;
//    }
// }
.showfilterbtn button.min_button.button_ht.custom_button.bg_color{
  display: flex;
  align-items: center;
  padding-left: 36px;
  &::before{
    content: "";
    position: absolute;
    background: url('./assets/images/filter.png');
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 12px;
  }
  .mat-button-focus-overlay{
    display: none;
  }
  &:hover{
    &::before{
      background: url('./assets/images/filter_black.png') no-repeat;
      background-size: contain;
    }
  }
}
.searchplaylist,.searchscreenlis{
    position: absolute;
    z-index: 9;
    background: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 250px;
    padding: 10px 0px;
    overflow: auto;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, .1);
    top: calc(100% + 10px);
    border: 1px solid #f7f7f7;
    width: 100%;
    .custom-tableplaylist {
      tr{
          background-color: transparent;
          margin-bottom: 0px;
          border-radius: unset;
          border-bottom: 1px solid #ddd;
          td{
                padding: 20px 10px;
          }
          &:last-child{
            border-bottom: 0px;
          }
      }
    }
    mat-table{
      height: auto !important;
      mat-header-row{
        display: none;
      }
      mat-row{
          background-color: transparent;
          margin-bottom: 0px;
          border-radius: unset;
          border-bottom: 1px solid #ddd !important;
          min-height: 57px;
        mat-column-checkbox{
          padding-left: 30px !important;
        }
      }
    }
}
.custom-tableplaylist {
  height: 53vh;
  overflow-y: auto;
  width: 100%;
  display: block;
&.custom-tableplaylistsearch{
      height: auto;
  thead,tbody{
    display: block;
    border: none;
  }
}
  thead {
    display: flex;
    border-width: 0;
    border-bottom-width: 10px !important;
    border-style: solid;
    align-items: center;
    box-sizing: border-box;
    border-color: white;
    tr {
      background-color: transparent;

      th {
        margin-bottom: 0;
        padding-bottom: 0;
        text-transform: uppercase;
        font-size: 14px;
        color: #6B6B6B;
        font-weight: 500;
      }
    }
  }
tbody{
  display: flex;
  flex-wrap: wrap;
  border-width: 0;
  border-bottom-width: 10px !important;
  border-style: solid;
  align-items: center;
  box-sizing: border-box;
  border-color: white;
}
  tr {
    width: 100%;
    background-color: #fafafb;
    margin-bottom: 19px;
    display: inline-block;
    border-radius: 8px;

    td,
    th {
      padding: 25px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      cursor: pointer;
      width: 30%;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-align: center;
      &.playistname{
        text-align: left;
        padding-left: 20px;
      }
      &:first-child {
        width: 10%;
        text-align: right;
        padding-left: 20px;
      }
    }
    th{
      &:nth-child(2){
        text-align: left;
      }
    }
  }
}
.terms_coonditins{
    padding: 0px 52px 15px;
    font-size: 16px;
    color: #000;
    word-break: break-all;
    max-height: 400px;
    overflow-y: auto;
}
.players_slider{
  .carousel {
      div, li{
          height: 100%;
      }
  }
  .slick-dots{
      right: 0;
      left: initial;
      top: -25px;
      bottom: initial;
      text-align: right;
      li{
          margin: 0px;
          button{
              width: 22px;
              height: 22px !important;
              border-radius: 100px;
              padding: 0px;
              background: #B5B5B5;
              border:2px solid #fff;
              &:before{
                display:none;
              }
          }
          margin-left: -11px;
          &.slick-active{
            button{
              background: #575957;
            }
          }
      }
  }
}
.main_dashboard_wrap{
  .body{
      padding-right: 4px;
  }
}
.upcoming_playlist{
  .mat-calendar {
    .mat-calendar-header {
      .mat-calendar-controls{
        border-bottom: 0px !important;
        margin-bottom: 4px;
      }
    }
  }
  .mat-calendar-content{
    padding: 0px !important;
  }
  .mat-calendar-body{
    tr{
      &:first-child{
        display: none;
      }
    }
  }
  .mat-calendar-controls{
    button{
      padding-left: 8px;
      span{
        font-size: 18px;
        color: #202020;
        margin-right: 4px;
      }
    }
  }
  .mat-calendar-content {
    .mat-calendar-table-header {
      // span{
      //   color: #3E8BFF;
      // }
    }

    .mat-calendar-body {
      // .mat-calendar-body-today.mat-calendar-body-selected, .mat-calendar-body-selected{
      //     background: #3A6FF8;
      // }
    }
    .mat-calendar-table-header{
      // th{
      //   padding-bottom: 6px;
      // }
    }
    // .mat-calendar-body-cell-container {
    //     padding-top: 5.7% !important;
    //     padding-bottom: 5.7% !important;
    // }
  }
}
.no_data {
  min-height: calc(100vh - 150px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  h3{
    font-size: 38px;
    font-weight: 500;
    color: #070031B2;
    margin: 0px 0px 22px;
  }
  p{
    font-size: 22px;
    font-weight: 500;
    margin: 0px 0px 20px;
    color: #313731;
  }
  app-file-upload{
    text-align: center;
    .file-drop-area {
      display: inline-block !important;
      width: 99px !important;
      height: 99px !important;
      background: url('./assets/images/upload_icon.png') center center no-repeat !important;
      border: 0px !important;
      padding: 0px !important;
      margin-top: 15px !important;
      &::after, &::before{
        display: none;
      }
    }
  }
}
.checklistmain{
  app-screen-list{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 44px);
    margin-left: -22px;
    > div{
      width: calc(33.33% - 44px);
      margin: 0 22px;
      .checklist{
        .mat-checkbox{
          display: block;
          margin-bottom: 11px;
          &.mat-checkbox-checked{
            label{
              .mat-checkbox-label {
                color: rgba(50, 53, 57, 1);
              }
            }
          }
          label{
            width: 100%;
            .mat-checkbox-inner-container{
              margin-left: 0;
            }
            .mat-checkbox-label{
                padding: 10px 22px 3px;
                line-height: 16px;
                color: #8f9bb3;
                background-color: #eff4fa;
                border-radius: 4px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                font-size: 16px;
                width: calc(100% - 28px);
                height: 36px;
            }
          }
        }
      }
    }
    .no_data{
      width: 100%;
      margin: 0;
      min-height: auto;
      flex-wrap: wrap;
      p{
          width: 100%;
          margin-bottom: 30px !important;
          font-size: 24px !important;
          text-transform: capitalize;
          margin-top: 50px !important;
      }
    }
  }
}
.radiocheckmain{
  app-popup-playlist{
      display: flex;
      flex-wrap: wrap;
        >div {
          width: calc(33.33% - 15px);
          margin-right: 22px;

          &:nth-child(3n) {
            margin-right: 0;
          }
          .checklist {
            .mat-radio-button {
              display: block;
              margin-bottom: 11px;

              &.mat-radio-checked {
                label {
                  .mat-radio-label-content {
                    color: rgba(50, 53, 57, 1);
                  }
                }
              }

              label {
                width: 100%;

                .mat-radio-container {
                  margin-left: 0;
                  margin-right: 15px;
                }

                .mat-radio-label-content {
                  padding: 10px 22px 3px;
                  line-height: 16px;
                  color: #8f9bb3;
                  background-color: #eff4fa;
                  border-radius: 4px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  text-align: center;
                  font-size: 16px;
                  width: calc(100% - 28px);
                  height: 36px;
                }
              }
            }
          }
        }
  }
}
.searchplaylist{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    >div {
        width: calc(32.33% - 15px);
        margin-right: 22px;
        margin-top: 15px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .checklist {
          .mat-radio-button {
            display: block;
            margin-bottom: 11px;

            &.mat-radio-checked {
              label {
                .mat-radio-label-content {
                  color: rgba(50, 53, 57, 1);
                }
              }
            }

            label {
              width: 100%;

              .mat-radio-container {
                margin-left: 0;
                margin-right: 15px;
              }

              .mat-radio-label-content {
                padding: 10px 22px 3px;
                line-height: 16px;
                color: #8f9bb3;
                background-color: #eff4fa;
                border-radius: 4px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                font-size: 16px;
                width: calc(100% - 28px);
                height: 36px;
              }
            }
          }
        }
      }
}
.searchscreenlis {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    >div {
      width: calc(32.33% - 15px);
      margin-right: 22px;
      margin-top: 15x;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .checklist {
        .mat-checkbox {
          display: block;
          margin-bottom: 11px;

          &.mat-checkbox-checked {
            label {
              .mat-checkbox-label {
                color: rgba(50, 53, 57, 1);
              }
            }
          }

          label {
            width: 100%;

            .mat-checkbox-inner-container {
              margin-left: 0;
            }

            .mat-checkbox-label {
              padding: 10px 22px 3px;
              line-height: 16px;
              color: #8f9bb3;
              background-color: #eff4fa;
              border-radius: 4px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              text-align: center;
              font-size: 16px;
              width: calc(100% - 24px);
              height: 36px;
            }
          }
        }
      }
    }
}
.radiocheckmain{
  height: 42vh;
  padding: 15px 0;
}
.checklistmain {
  height: 24vh;
  padding: 15px 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, .2);
}
.zero-length{
  app-popup{
    app-popup-search {
      display: none;
    }
  }
    .cdk-global-overlay-wrapper .cdk-overlay-pane .button_wrap {
      display: none;
    }

}
.templateheader{
  padding-top: 27px;
  padding-bottom: 25px;
  .my-div{
    h2{
        margin: 0;
        font-size: 24px;
        color: #000;
        font-weight: 500;
    }
  }
}
.display_none{
  display: none !important;
}

@media(max-width:1700px){
  .fc.fc-theme-standard{
    .fc-v-event, .fc-h-event {
        .custom-event {
            .button_event_wrap{
                padding: 5px 12px 5px 12px;
                button{
                    font-size: 12px !important;
                    padding-right: 0px;
                }
            }
            & > div:not(.button_event_wrap){
                padding: 12px 12px 18px;
                background: #FFFFFF;
                border-radius: 8px;
                margin-top: 3px;
                height: calc(100% - 0px);
                .event-name{
                    font-size: 14px;
                    margin: 0px 0px 5px;
                }
                .start_end_time{
                    font-size: 10px;
                }
            }
        }
    }
  }
}
@media(max-width:1280px){
  .select_orientation {
    mat-radio-button {
      .mat-radio-label{
        padding: 14px 50px 14px 24px;
        .mat-radio-label {
          .mat-radio-label-content{
            font-size: 17px;
            padding-left: 16px;
          }
        }
      }
    }
  }
}
@media(max-width:1200px){
  .fc.fc-theme-standard {
    .fc-scrollgrid-section-header {
      .fc-col-header {
        .fc-scrollgrid-sync-inner {
          a {
            &:before{
              width: 25px;
              height: 25px;
              font-size: 11px;
              line-height: 26px;
            }
            font-size: 13px !important;
            color: #292F36;
            opacity: 0.8;
            display: block;
            width: 100%;
            margin: 6px 0px 0px;
          }
        }
      }
    }
  }
}
@media(max-width:992px){
.accordion-closed {
    display: none;
  }
body.increased_width_lg .cdk-global-overlay-wrapper .cdk-overlay-pane {
    width: 100% !important;
    .mat-dialog-container {
        width: fit-content !important;
        box-shadow: none;
        overflow: hidden;
      }

      .closebtnpopup {
        position: absolute;
        right: 16px;
        top: 4px;
        z-index: 9;
      }
        .form {
          app-preview-playlist {
            .popup_preview {
              .current_layout{
                width: 95% !important;
                border: 15px solid #fff;
                border-radius: 16px;
              }
            }
          }
        }
  }


  button.min_button.button_ht.custom_button{
    height: 45px !important;
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      width: calc(100% - 30px) !important;
      .popup_heading {
        h2{
          padding: 34px 40px 29px;
        }
        .grey_bar {
          height: 40px;
          padding: 27px 40px 20px;
        }
      }
      .form{
        padding: 35px 40px;
      }
      .button_wrap {
        button{
          margin-right: 20px;
        }
        padding: 25px 40px;
      }
    }
  }
  app-recent-item-popup {
    .playlist_form {
      .select2-selection.select2-selection--multiple {
        .select2-selection__choice {
          margin-right: 4px;
          padding: 7px 8px 7px 10px;
          button{
            height: auto!important;
          }
        }
      }
    }
  }
  .mobile_data_table{
    tbody{
      tr{
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 3px 15px 10px 15px;

      }
      td{
        padding: 10px 10px;
        a{
          color: #000;
        }
        &:before{
          font-size: 12px;
        }
        span{
          font-size: 14px !important;
        }
        .wrap_column_image{

          margin-right: 15px;
          .folder_icon {
            img{
              max-width: 25px;
            }
          }
        }
        &.actions{
          width: 100% !important;
          border-bottom: 1px solid #f0f0f0;
          padding: 0px 10px 5px;
          button{
            width: auto;
            height: auto !important;
          }
          .mat-button-wrapper{
            display: block;
            width:100%;
            & > span{
              font-size: 16px;
              text-align: left;
              float: left;
              height: auto;
              line-height: initial;
            }
            .mat-icon{
              float: none;
              position: relative;
              top: 0;
            }
          }
        }
        &:not(.actions){
          width: auto !important;
          padding-right: 15px;
          padding-bottom: 5px;
          &:last-child{
            padding-right: 0px;
          }
        }
      }
    }
  }
  .mat-menu-panel{
    padding: 10px 0px 0px;
    button.mat-menu-item {
        padding: 10px 17px;
        font-size: 15px !important;
        height: auto !important;
        &:last-child.delete{
          margin-top: 10px;
          padding: 11px 18px 22px;
        }
    }
  }
  .mob_action{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mbaciton{
      height: auto !important;
    }
  }
  .upcoming_playlist {
    .mat-calendar {

      .mat-calendar-previous-button,
      .mat-calendar-next-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        .mat-button-focus-overlay{
          background: transparent;
          display: none;
        }
        &::after {
          top: 11px;
          left: auto;
          right: auto;
          width: 12px;
          height: 12px;
          margin: 0;
        }
      }
    }
  }
}
@media(max-width:768px){
  .module_wrap {
    .page_wrap{
      padding: 0px;
      padding-top: 25px;
    }
  }
  button.min_button.button_ht.custom_button{
    height: initial !important;
    padding: 3px 25px;
  }
  .recent_items{
      padding: 0px 0px 0px;
      ul {
        &.mobile_recent_list{
          display: block;
          &.slot-box{
            display: flex;
            margin: 0px 0px 35px;
            height: 500px;
            overflow: auto;
          }

          li{
            width: auto;
            margin-right: 0px;
            margin-bottom: 0px;
          }
          .slick-slide {
              margin: 0 10px;
          }
          .slick-list {
              margin: 0 -10px;
          }
        }
      }
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .button_wrap {
        & > div{
          margin-left: 8px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
      width: calc(100% - 30px) !important;
      .popup_heading {
        h2{
          padding: 22px 20px 16px;
        }
        .grey_bar {
          height: 30px;
          padding: 18px 20px 18px;
        }
      }
      .form{
        padding: 32px 20px;
        input{
          padding: 11px 18px;
        }
      }
      .button_wrap {
        padding: 22px 20px;
        button{
          margin-right: 18px;
          font-size: 16px !important;
          line-height: 25px;
          padding: 0;
          height: initial !important;
          border-radius: 8px;
          span{
            padding: 3px 25px;
          }
          &.mat-warn{
              line-height: 25px;
          }
        }
      }
    }
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .popup_heading {
        h2 {
          padding: 22px 20px 16px;
          font-size: 13px;
        }

        app-button {
          padding-right: 20px !important;

          button {
            padding: 3px 20px;
            font-size: 13px !important;
          }
        }
      }
    }
  }
  app-recent-item-popup {
    .menu_popup_content {
      .right {
        padding-left: 20px;
        width: calc(100% - 250px);
      }
      .menu_item {
        width: 250px;
      }
    }
    .playlist_form {
      margin-top: 0px;
      label{
        width: 100% !important;
        margin-bottom: 7px;
      }
      .form_control{
        margin-bottom: 20px;
        width: 100%;
      }
      .select2 {
        width: 100% !important;
      }
    }
  }
  .cdk-overlay-connected-position-bounding-box{
    .cdk-overlay-pane{
      margin-left: 0;
      margin-right: 0px;
    }
  }
  .card {
    .body{
      padding: 0px 10px 0px 0px;
    }
  }
  .right_bar{
    margin-left: 10px;
    margin-top: 0;
    margin-right:0;
    button.min_button.button_ht.custom_button.canva {
        background-color: #fff;
        color: #000 !important;
        padding: 0 !important;
        width: 30px !important;
        height: 30px !important;
        min-width: auto !important;
        border: none;
        background-position: left 0 !important;
        background-size: 30px !important;
        .mat-button-wrapper{
          display: none;
        }
    }
  }


  .not_searchBar{
    .module_wrap {
      .page_wrap{
        padding-top: 15px;
      }
    }
  }
}
app-show-playlist{
  width: 100%;
}
@media (max-width:576px) {

  .navbar {
    .collapse-menu-icon{
      height: auto;
    }
  }
  .create_playlist_btn {
    div{
      display: flex;
    }
    .createbtn button.min_button.button_ht.custom_button.bg_color {
      background: #272D34 url('./assets/images/compose.png') left 15px center no-repeat;
      background-color: #fff;
      color: #000 !important;
      padding: 0 !important;
      width: 35px !important;
      height: 35px !important;
      min-width: auto !important;
      border: none;
      background-position: left 0 !important;
      background-size: 35px !important;
      span{
        display: none;
      }
    }
  }
    .upcoming_playlist .mat-calendar .mat-calendar-previous-button,
    .upcoming_playlist .mat-calendar .mat-calendar-next-button{
        width: 22px;
    }
}
@media(max-width:520px){
  app-header .navbar .search_box_wrap app-search {
      .mat-form-field-label-wrapper .mat-form-field-label {
        font-size: 11px;
        top: 1.8em;
        padding-left: 28px;
      }

      input {
        padding: 15px 22px 15px 28px;
        background: #F5F7F9 url('./assets/images/search_icon.png') left 8px top 15px no-repeat;
        background-size: 14px;
      }
    }

  .action_wrap {
      flex-wrap: nowrap;

      .dropdown_wrap .dropdown_btn {
        padding: 2px 12px;
        font-size: 13px !important;
        height: 40px !important;
        line-height: 33px;

        .mat-button-wrapper:after {
          margin-left: 3px;
        }
      }

      button.min_button.button_ht.custom_button {
        font-size: 13px !important;
      }

      .content_folderbtns button.min_button.button_ht.custom_button span {
        padding: 3px 12px;
      }
       .dropdown_wrap:last-child .pointer{
        margin-left: 5px;
       }
      .dropdown_wrap .pointer{
        margin-left: 5px;
       }
    }
  app-recent-item-popup {
    .menu_popup_content {
      flex-wrap: wrap;
      .right {
        padding-left: 0px;
        width: 100%;
        text-align:left;
        margin-top:20px;
        form{
          @include flexbox;
          @include flex-wrap(wrap);
          @include justify-content(center);
          app-button.min_button{
            &:first-child{
              min-width: 100% !important;
              button{
                width: 100%;
                background-position: left 12px center;
                min-width: 100% !important;
                border: 1px solid #D6D6D6;

              }
            }
            min-width: calc(50% - 8px) !important;
            button{
              min-width: 50% !important;
              width: 100%;
            }
            &:last-child{
              margin-left: 15px;
            }
          }
        }
        button{
          &:not(.select2-selection__choice__remove){
            margin-bottom: 14px;
          }
        }
      }
      .menu_item {
        width: 100%;
      }
    }
  }
  .mobile_data_table {
    tbody {
      td:not(.actions){
        width: 50% !important;
      }
    }
  }
  .mat-paginator-page-size {
    margin: 0 8px 0 10px;
    .mat-paginator-page-size-label{
      display: none;
    }
  }
  .new_schedule_mob_form, .custom_form_style {
    .form_control, .form-group{
      margin-bottom: 20px;
      &.checkbox_group{
        width: 100% !important;
      }
      .mat-form-field, input{
        width: 100% !important;
      }
      &:first-child{
        label{
          margin-bottom: 0px;
        }
      }
      label{
        margin-right: 0px;
        width: 100%;
        margin-bottom: 10px;
      }
      .flex_ends{
        display: block !important;
      }
      div.wd_70{
        width: 100% !important;
        input{
          padding: 23px 20px !important;
        }
      }
      &:first-child {
        label{
          margin-bottom: 10px;
        }
      }
    }
  }
  .increased_width {
    .cdk-global-overlay-wrapper {
      .cdk-overlay-pane {
        .form{
          padding: 30px 20px 30px 20px;
        }
      }
    }
  }
  .popup_heading{
    .schedule_name{
      padding: 22px 20px;
    }
  }
  .select_orientation {
    mat-radio-button {
      .mat-radio-label{
        padding: 12px 18px 12px 18px;
        .mat-radio-label-content{
          font-size: 15px !important;
          padding-left: 10px !important;
        }
      }
    }
  }
  .mobile_data_table tbody td:not(.actions)[data-title="Name"] {
    // width: 100% !important;
    // border-bottom: 1px solid #f0f0f0;
    // padding: 0px 10px 5px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .popup_heading {
          app-button {
          padding-right: 10px !important;
          button {
            padding: 3px 20px;
            font-size: 13px !important;
          }
        }
      }

    }
  }
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      .popup_heading {
        .grey_bar{
              height: 10px;
              padding: 10px 20px 10px;
        }

      }
      .button_wrap {
        padding: 10px 10px;
      }
      .form {
        padding: 15px 15px;
      }
    }

  }
  app-recent-item-popup{
    .menu_popup_content {
      h4{
            font-size: 13px;
            padding: 0;
            margin: 10px 12px;
      }
      .right{
          margin-top: 12px;
          form{
            padding-bottom: 8px;
          app-button.min_button{
            button{
                  margin-bottom: 8px;
                  font-size: 13px !important;
            }
          }
          }
      }
    }

  }
  .mobile_data_table{
    tbody {
      tr{
            padding: 3px 0px 10px 0px;
            td {
              span{
                    font-size: 13px !important;
              }
              .wrap_column_image{
                    margin-right: 10px;
              }

              .title__ .text__ {
                      width: 100%;
                        font-size: 12px;
                }
            }
            td[data-title="Name"] {
              .title__ .text__ {
                width: calc(100% - 42px);
                font-size: 12px;
              }
            }
            span:not(.button) {
              height: auto;
              font-size: 13px;
              font-weight: 500;
              margin-top: 5px;
            }
      }
    }
  }
  .custom_datatable {
    .mat-paginator-outer-container {
      margin-top: 10px;

      .mat-paginator-container {
        justify-content: space-between;

        .mat-paginator-page-size {
          margin-right: 5px;
          margin-left: 0;

          .mat-paginator-page-size-select {
            width: 53px;
            margin-left: 0;
          }
        }

        .mat-select {
          padding: 12px 7px;
          width: 53px;

          .mat-select-arrow-wrapper {
            height: 14px;

            .mat-select-arrow {
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
              border-top: 3px solid;
              margin: 0 2px;
            }
          }
        }

        .mat-icon-button {
          width: auto;
          height: auto !important;

          .mat-paginator-icon {
            width: 24px;
          }
        }
      }
    }
  }
  .dropdown_wrap .dropdown_btn{
        font-size: 13px !important;
        padding: 2px 15px;
  }
  .play_list_slots.recent_items {
    .mat-paginator-outer-container {
      .mat-paginator-container {
        padding: 0 0;
        flex-wrap: nowrap;
        justify-content: space-between;
        .mat-paginator-page-size {
          margin-left: 0;
          .mat-paginator-page-size-select {
            width: 43px;
            margin: 6px 0px 0 0px;
          }
        }
        .mat-paginator-range-label {
          margin: 0 12px 0 12px;
        }
      }
    }
  }
}

@media(max-width:400px){
  app-recent-item-popup {
    .menu_popup_content {
      .right {
        form{
          app-button.min_button{
            min-width: 100% !important;
            button{
              min-width: 100% !important;
              width: 100%;
              margin-bottom: 10px;
            }
            &:last-child{
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  .recent_items ul li .image_wrap{
    height: 120px;
  }
}

.content_folderbtns button.min_button.button_ht.custom_button {
  padding: 0;

  span {
    padding: 3px 29px;
    display: block;
  }
}
@media (max-width: 768px) {
  .content_folderbtns button.min_button.button_ht.custom_button {
    padding: 0;
    span {
      padding: 3px 25px;
    }
  }
    .empty_slots_boxes .slick-arrow {
      height: 40px !important;
    }

    .empty_slots_boxes .slick-arrow.slick-prev {
      left: -8px;
    }

    .empty_slots_boxes .slick-arrow.slick-next {
      right: -8px;
    }
}

.custom_datatable{
  .mat-sort-header-arrow {
      opacity: 0 !important;
      &[aria-sort='none']{
        opacity: 0 !important;
      }
    }
  .custom-class {
      .mat-sort-header-arrow {
        opacity: 1 !important;
      }
      &[aria-sort='none'] {
        .mat-sort-header-arrow {
            opacity: 1 !important;
             background-image: url(assets/images/dbarrow.png);
             background-size: contain;
             background-repeat: no-repeat;
             transform: translateY(0) !important;
             width: 22px;
             height: 11px;
             .mat-sort-header-indicator,.mat-sort-header-stem{
              display: none;
             }
          }
      }
    }
}



